import React, {useEffect} from 'react';
import './Header.css';  // Optional: Add styles for the header

const Header = () => {

  useEffect(() => {
    const navbar = document.getElementById('navbar');
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScroll > lastScrollTop) {
        navbar.style.top = '-80px'; // Hide navbar
      } else {
        navbar.style.top = '0'; // Show navbar
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    };
  }, []);

  return (
    <header id="navbar" className="header">

      <nav>
        <ul className="nav-list">
          <li><a href="#hero">Home</a></li>       
          <li><a href="#products-features">Features</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
