import React from 'react';
import './HeroSection.css';
import logo from '../assets/logonew1.png'; 

const HeroSection = () => {
  return (
    <section id = "hero" className = "hero-transparent-box">
      <div className="hero-content">
        <img src={logo} alt="Company Logo" className="hero-logo" />  {/* Logo Image */}
        <div className="text-content">
          <h1 className="right-align"><span className="highlighthero">Revolutionizing</span><br /> B2B Content Engagement <br /></h1> {/* Right-aligned h1 */}
          <p>
            In the fast-evolving world of B2B content <br /> nearly two-thirds fail to engage its target audience. <br />
            At Anov8, we tackle this challenge head-on <br /> with our pioneering Impact Predictor, designed <br /> to transform your content strategy.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
