import React from 'react';
import './ContactSection.css'; 


const ContactSection = () => {
  return (
    <section id="contact" className="contact transparent-box">
      <h2>Contact Us</h2>
      <form>
        <input type="text" placeholder="Your Name" required />
        <input type="email" placeholder="Your Email" required />
        <textarea placeholder="Your Message" required></textarea>
        <button type="submit" className="custom-button">Send Message</button>
      </form>
    </section>
  );
};

export default ContactSection;

