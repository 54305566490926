import React from 'react';
import './ProductFeaturesSection.css';
import './TokenForm.css';
import icon1 from '../assets/predict.png';  // Import local icons
import icon2 from '../assets/perfect.png';
import icon3 from '../assets/perform.png';
import iplogo from '../assets/iplogo.png';


const ProductFeaturesSection = ({ onProductClick }) => {
  return (
    <section id="products-features" className="product-features transparent-box">
      {/* Logo and Text Section */}
      <div className="logo-text-container">
        <div className="text-box text-left">
          <h1>From <br /> Guesswork <br /> to <br /> Greatness:</h1> <br />
          <h2>Are you ready to <br /> <span className="highlight">Transform</span> <br /> your content strategy?</h2>
        </div>
        <div className="logo-box">
          <img src={iplogo} alt="logo" className="logo-image" />
        </div>
      </div>

      {/* Features Section */}
      <div className="carousel-box">
        <div className="feature-grid">
          <div className="feature-item">
            <img src={icon1} alt="AI Prediction Feature Icon" className="feature-icon" />
            <h3>Predict</h3>
            <p>Harness the power of advanced AI to analyze and forecast content effectiveness. Identify which pieces will resonate with your audience and which will miss the mark.</p>
          </div>
          <div className="feature-item">
            <img src={icon2} alt="Perfect Content Strategy Icon" className="feature-icon" />
            <h3>Perfect</h3>
            <p>Refine your content strategy with insights that eliminate guesswork. Create high-impact content that aligns perfectly with your audience’s needs and preferences.</p>
          </div>
          <div className="feature-item">
            <img src={icon3} alt="Performance Measurement Icon" className="feature-icon" />
            <h3>Perform</h3>
            <p>Drive measurable results with content that engages, converts, and performs. Achieve greater engagement success and improve campaign ROI.</p>
          </div>
        </div>
      </div>

      {/* Try Now Button */}
      <div className="button-box">
        <button className="custom-button" onClick={onProductClick}>
          Try Now
        </button>
      </div>
    </section>
  );
};

export default ProductFeaturesSection;
